import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Section.styles.scss'

const Section = ({
  bgColor,
  children,
  className,
  id,
  hasPaddingTop,
  hasPaddingBottom,
  ...attributes
}) => {
  return (
    <section
      id={id}
      className={classNames(styles.Section, className, {
        [styles.hasPaddingTop]: hasPaddingTop,
        [styles.hasPaddingBottom]: hasPaddingBottom,
      })}
      data-bg={bgColor}
      {...attributes}
    >
      <div className={styles.bgTop} />
      {children}
      <div className={styles.bgBottom} />
    </section>
  )
}

Section.defaultProps = {
  bgColor: 'white',
  hasPaddingTop: true,
  hasPaddingBottom: true,
}

Section.propTypes = {
  bgColor: PropTypes.oneOf(['custom', 'black', 'white', 'green', 'gold']),
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  hasPaddingTop: PropTypes.bool,
  hasPaddingBottom: PropTypes.bool,
}

export { Section }
