import React from 'react'
import PropTypes from 'prop-types'

/**
 * SectionRouter outputs sections
 *
 * const types = {
 *   example_section: ExampleSection,
 * }
 */

const SectionRouter = ({ sections, types }) => {
  const output = []
  let prevSection = 'none'

  sections.forEach((section, index) => {
    const sectionType = section.type
    const SectionType = types[sectionType]
    const nextSection = sections[index + 1] ? sections[index + 1].type : 'none'

    if (SectionType) {
      output.push(
        <SectionType
          key={section.id}
          prevSection={prevSection}
          nextSection={nextSection}
          {...section}
        />
      )

      prevSection = sectionType
    }
  })

  return output
}

SectionRouter.defaultProps = {
  sections: [],
  types: {},
}

SectionRouter.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  types: PropTypes.object,
}

export { SectionRouter }
