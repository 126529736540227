import { getRichText } from './getRichText'
// import { getAspectRatio } from './getAspectRatio'
import { getLink } from './getLink'
import { getSlug } from '@/utils/getSlug'
import { get } from '@/utils/get'
import { getImage } from '@/prismic/utils/getImage'
import { useBlogPosts } from '@/prismic/hooks/useBlogPosts'
import { sectionSerializer as subSectionSerializer } from '@/prismic/utils/sectionSerializer'
import { linkResolver } from '@/prismic/utils/linkResolver'
import { useSiteConfig } from '@/prismic/hooks/useSiteConfig'

/**
 * Process prismic slice data that can be passed to components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const sectionSerializer = ({
  slices,
  location = {},
  pageBgColor = 'white',
}) => {
  const sections = []

  slices.forEach((slice, sliceIndex) => {
    if (slice) {
      // section object
      const section = {
        type: slice.slice_type,
        id: `section_${sliceIndex}`,
        location: location,
      }

      const site = useSiteConfig()

      // restructure 'primary' object prop to root
      if (slice.primary) {
        Object.keys(slice.primary).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(slice.primary, key)) {
            slice[key] = slice.primary[key]
          }
        })
        delete slice.primary
      }

      // hero
      if (slice.slice_type === 'hero') {
        section.heading = get(slice, 'heading.text')
        section.registerBtnLabel =
          slice.register_button_label || 'Register My Interest'
        if (slices[sliceIndex + 1]?.slice_type === 'logo_animation') {
          section.scrollToId = 'logo-animation'
        }
      }

      // logo animation
      if (slice.slice_type === 'logo_animation') {
        section.image = getImage(slice, 'image', 'fluid')
        section.imageMobile = getImage(slice, 'image_mobile', 'fluid')
        section.scrollId = 'logo-animation'
      }

      // feature
      else if (slice.slice_type === 'feature') {
        section.bgColor = getSlug(slice.background_color)
        section.icon = getSlug(slice.icon)
        section.subheading = getRichText(slice, 'subheading.raw', 'p')
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.intro = getRichText(slice, 'intro.raw')
        section.description = getRichText(slice, 'description.raw')
        section.image = getImage(slice, 'image', 'fluid')
        section.link = getLink(
          slice.link,
          get(slice, 'link_label') || 'Read more'
        )
      }

      // intro
      else if (slice.slice_type === 'intro') {
        section.subheading = getRichText(slice, 'subheading.raw', 'p')
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.description = getRichText(slice, 'description.raw')
        section.image = getImage(slice, 'image', 'fluid')
        section.bgColor = get(section, 'image.src')
          ? 'custom'
          : getSlug(slice.background_color)
        section.link = getLink(
          slice.link,
          get(slice, 'link_label') || 'Read more'
        )
      }

      // blog posts
      else if (slice.slice_type === 'blog_posts') {
        section.id = `blog_posts_${sliceIndex}`
        section.heading = getRichText(slice, 'heading.raw', 'p')
        const limit = slice.posts === 'Latest' ? 3 : undefined
        section.posts = useBlogPosts(limit)
        section.link = getLink(
          slice.link,
          get(slice, 'link_label') || 'Read more'
        )
      }

      // page header
      else if (slice.slice_type === 'header') {
        section.bgColor = pageBgColor

        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.subheading = getRichText(slice, 'subheading.raw', 'p')
        section.description = getRichText(slice, 'description.raw')
        section.icon = getSlug(slice.icon)
      }

      // page_link
      else if (slice.slice_type === 'page_link') {
        const uid = get(slice, 'document_link.document.uid')
        if (uid) {
          section.link = {
            url: linkResolver({ uid }),
            title: get(slice, 'document_link.document.data.title'),
          }
        }
        section.label = slice.label
      }

      // stats
      else if (slice.slice_type === 'stats') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.items = []
        slice.items.forEach((item, index) => {
          section.items.push({
            id: `${sliceIndex}-${index}`,
            number: item.number,
            description: getRichText(item, 'description.raw'),
          })
        })
      }

      // text_and_image
      else if (slice.slice_type === 'text_and_image') {
        section.bgColor = getSlug(slice.bg_color)
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.headingColor = getSlug(slice.heading_color)
        section.description = getRichText(slice, 'description.raw')
        section.image = getImage(slice, 'image', 'fluid')
        section.imageAlign = getSlug(slice.image_vertical_align)
        section.borderTop = slice.border_top
      }

      // info
      else if (slice.slice_type === 'info') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.icon = getSlug(slice.icon)
        section.intro = getRichText(slice, 'intro.raw')
        section.image = getImage(slice, 'image', 'fluid')
        section.bodyText = getRichText(slice, 'body_text.raw')
        section.borderTop = slice.border_top
      }

      // text
      else if (slice.slice_type === 'text') {
        section.bgColor = getSlug(slice.background_color)
        section.bodyText = getRichText(slice, 'body_text.raw')
      }

      // video
      else if (slice.slice_type === 'video') {
        section.bgColor = getSlug(slice.background_color)
        section.video = { url: slice.video_url, aspectRatio: 0.5625 }
        section.posterImg = getImage(slice, 'poster', 'fluid')
      }

      // image
      else if (slice.slice_type === 'image') {
        section.bgColor = getSlug(slice.background_color)
        section.image = getImage(slice, 'image', 'fluid')
        section.caption = getRichText(slice, 'caption.raw')
      }

      // cta
      else if (slice.slice_type === 'cta') {
        section.bgColor = getSlug(slice.background_color)
        section.link = getLink(
          slice.link,
          get(slice, 'link_label') || 'Read more'
        )
      }

      // faqs
      else if (slice.slice_type === 'faqs') {
        section.faqs = []
        slice.items.forEach((node, index) => {
          const { document } = node.faq
          const faqSlices = get(document, 'data.body') || []
          const faqSections = subSectionSerializer({ slices: faqSlices })

          section.faqs.push({
            id: `faq-${sliceIndex}-${index}`,
            title: document.data.title,
            intro: getRichText(document, 'data.intro.raw'),
            url: linkResolver(document),
            sections: faqSections,
          })
        })
      }

      // image comparison slider
      else if (slice.slice_type === 'image_comparison_slider') {
        section.bgColor = getSlug(slice.background_color)
        section.image1 = getImage(slice, 'image_01', 'fluid')
        section.image2 = getImage(slice, 'image_02', 'fluid')
        section.description = getRichText(slice, 'description.raw')
      }

      // image comparison slider
      else if (slice.slice_type === 'register_form') {
        section.url = site.register.url
        section.fields = site.register.fields
        section.successTitle = site.register.successTitle
        section.successDesc = site.register.successDesc
      }

      // add to output array
      sections.push(section)
    }
  })

  return sections
}
