import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import { RichText } from '@/components/RichText'
import { Img } from '@/components/Img'
import * as styles from './Image.styles.scss'

const Image = ({ bgColor, caption, image }) => {
  return (
    <Section bgColor={bgColor}>
      <Container>
        {image && image.src && (
          <div>
            <Img {...image} />
          </div>
        )}
        {caption && (
          <div className={styles.caption}>
            <div className={styles.caption__inner}>
              <RichText>{caption}</RichText>
            </div>
          </div>
        )}
      </Container>
    </Section>
  )
}

Image.propTypes = {
  bgColor: PropTypes.string,
  image: PropTypes.object,
  caption: PropTypes.node,
}

export { Image }
