import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getRichText } from '@/prismic/utils/getRichText'
import { linkResolver } from '@/prismic/utils/linkResolver'

const query = graphql`
  query BlogPosts {
    prismicBlogPosts: allPrismicBlogPost(
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          type
          data {
            title
            date(formatString: "Do MMMM YYYY")
            intro {
              raw
            }
          }
        }
      }
    }
  }
`

export const useBlogPosts = (limit = 100) => {
  const { prismicBlogPosts } = useStaticQuery(query)
  const posts = []

  prismicBlogPosts.edges.forEach(({ node }) => {
    posts.push({
      id: node.id,
      title: get(node, 'data.title'),
      date: get(node, 'data.date'),
      path: linkResolver(node),
      synopsis: getRichText(node, 'data.intro.raw'),
    })
  })

  return posts.slice(0, limit)
}
