import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Section } from '@/components/Section'
import * as styles from './Text.styles.scss'

const Text = ({ id, bgColor, bodyText }) => {
  return (
    <Section bgColor={bgColor} id={id}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.grid__inner}>
            <RichText>{bodyText}</RichText>
          </div>
        </div>
      </Container>
    </Section>
  )
}

Text.defaultProps = {
  id: 'text-section',
  bgColor: 'white',
  bodyText: undefined,
}

Text.propTypes = {
  id: PropTypes.string,
  bgColor: PropTypes.string,
  bodyText: PropTypes.node,
}

export { Text }
