import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import { Btn } from '@/components/Btn'
import { getBtnColors } from '@/utils/getBtnColors'
import * as styles from './Cta.styles.scss'

const Cta = ({ bgColor, link, prevSection }) => {
  return (
    <Section bgColor={bgColor}>
      <Container className={styles.container}>
        {link.url && (
          <Btn
            href={link.url}
            target={link.target}
            theme={getBtnColors(bgColor)}
          >
            {link.title}
          </Btn>
        )}
      </Container>
    </Section>
  )
}

Cta.propTypes = {
  bgColor: PropTypes.string,
  link: PropTypes.object,
}

export { Cta }
